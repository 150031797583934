<template>
  <div style="margin-top:20px;">
    <form class="md-layout">
      <md-card class="md-layout-item md-size-50 md-small-size-100">
        <md-card-header>
          <div class="md-title">Storico ordini</div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-datepicker
                v-model="form.dal"
                :disabled="isLoading"
                md-immediately
              >
                <label>Data ordine dal</label>
              </md-datepicker>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-datepicker
                v-model="form.al"
                :disabled="isLoading"
                md-immediately
              >
                <label>Data ordine al</label>
              </md-datepicker>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-radio v-model="form.evaso" value="false" v-on:change="cerca"
                >Non evaso</md-radio
              >
              <md-radio v-model="form.evaso" value="true" v-on:change="cerca"
                >Evaso</md-radio
              >
              <md-checkbox
                v-if="form.evaso === 'true'"
                type="check"
                name="spedito"
                id="spedito"
                v-on:change="cerca"
                v-model="form.spedito"
                :disabled="isLoading"
                >Spedito</md-checkbox
              >
            </div>
          </div>
        </md-card-content>

        <loading
          :active.sync="isLoading"
          :can-cancel="true"
          :on-cancel="onCancel"
          :is-full-page="true"
          color="#a20204"
        ></loading>

        <md-card-actions>
          <md-button
            type="button"
            class="md-primary"
            :disabled="isLoading"
            v-on:click="cerca"
            >Estrai ordini</md-button
          >
        </md-card-actions>
      </md-card>

      <md-snackbar :md-active.sync="userSaved"
        >Ordine memorizzato con successo</md-snackbar
      >

      <md-dialog-prompt
        :md-active.sync="promptQtaEvaso"
        v-model="qtaOrd"
        md-title="Digita la quantità che hai ordinato"
        md-input-maxlength="4"
        md-confirm-text="Ordinato"
        @md-confirm="evadi2"
      />

      <md-dialog-prompt
        :md-active.sync="promptQtaSpedito"
        v-model="qtaSped"
        md-title="Digita la quantità che hai spedito"
        md-input-maxlength="4"
        md-confirm-text="Spedito"
        @md-confirm="spedisci2"
      />
    </form>

    <VueyeTable
      :data="ordini"
      :columns="columns"
      title="Ultimi ordini"
      style="margin-top:20px;"
      :config="config"
    >      
      <template v-slot:ordinato="{ item }">
        {{ item.ord }}&nbsp;({{ item.qte }})
      </template>
      <template v-slot:spedito="{ item }">
        {{ item.sped }}&nbsp;({{ item.qts }})
      </template>
      <template v-slot:actions="{ item }">
        <div class="ve-table-actions">
          <md-button
            v-if="item.ord == null"
            class="md-primary md-raised"
            @click="evadi(item)"
          >
            Ordinato
          </md-button>
        </div>
        <div class="ve-table-actions">
          <md-button
            class="md-raised"
            v-if="item.ord != null && item.sped == null"
            @click="spedisci(item)"
          >
            Spedito
          </md-button>
        </div>
      </template>
    </VueyeTable>

    <md-dialog-alert
      :md-active.sync="msgboxerr"
      md-title="Avvenuto errore"
      :md-content="msgerr"
    />

    <md-snackbar :md-active.sync="userDeleted">
      Ordine segnalato come evaso
    </md-snackbar>
    <md-snackbar :md-active.sync="orderSent">
      Ordine segnalato come spedito
    </md-snackbar>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email, minLength } from "vuelidate/lib/validators";

import amazon from "../js/amazon.js";

import VueyeTable from "vueye-table";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "vue-moment";

export default {
  name: "FormValidation",
  mixins: [validationMixin],
  components: {
    VueyeTable,
    Loading,
  },
  data() {
    return {
      form: {
        dal: null,
        al: null,
        evaso: "false",
        spedito: false,
      },
      qtaOrd: null,
      qtaSped: null,
      selected: null,
      userSaved: false,
      userDeleted: false,
      orderSent: false,
      isLoading: false,
      msgboxerr: false,
      msgConferma: false,
      msgerr: "",
      msgConfermaTitle: "",
      msgConfermaText: "",
      msgConfermaYes: "",
      msgConfermaNo: "",
      promptQtaEvaso: false,
      promptQtaSpedito: false,
      ordini: [],
      config: {
        filterBy: "Cerca per",
        search: "Cerca",
        nbRowsPerPage: "Numero di righe per pagina",
        of: "di",
      },
      columns: [
        {
          key: "ins",
          label: "Inserito",
          sortable: true,
          display: true,
        },      
        {
          key: "nome",
          label: "Richiedente",
          sortable: true,
          display: true          
        },
        {
          key: "codice",
          label: "Codice",
          sortable: false,
          display: true,
        },
        {
          key: "descrizione",
          label: "Descrizione",
          sortable: false,
          display: true,
        },
        {
          key: "marca",
          label: "Marca",
          display: true,
          sortable: true,
        },
        {
          key: "qta",
          label: "Qtà",
          sortable: false,
          display: true,
        },
        {
          key: "urgenza",
          label: "Urgenza",
          sortable: true,
          display: true,
        },
        {
          key: "ord",          
          display: false,
        },
        {
          key: "sped",                    
          display: false,
        },
        {
          key: "ordinato",
          label: "Ordinato",
          sortable: true,
          display: true,
        },
          {
          key: "spedito",
          label: "Spedito",
          sortable: true,
          display: true,
        },
        {
          key: "actions",
          label: "Azioni",
          sortable: false,
          display: true,
        },
        {
          key: "_id",
          display: false,
        },
        {
          key: "id",
          display: false,
        },
        {
          key: "qte",
          display: false,
        },
        {
          key: "qts",
          display: false,
        }
      ],
    };
  },
  methods: {
    cerca() {
      this.isLoading = true;
      if (this.form.evaso === "false") {
        this.form.spedito = false;
      }
      amazon.getStorico(this.form, this.$moment).then((ris) => {
        this.isLoading = false;
        if (ris.startsWith("ERR")) {
          this.msgerr = ris;
          this.msgboxerr = true;
        } else {
          this.ordini = JSON.parse(ris);  
          this.ordini.forEach(o => {
            o.nome=o.insnome + " " +  o.inscognome;
          });         
          //console.log(JSON.stringify(this.ordini));
        }
      });
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.dal = null;
      this.form.al = null;
      this.form.evaso = false;
    },
    evadi(i) {
      this.selected = i;
      this.promptQtaEvaso = true;
    },
    evadi2(i) {
      if (this.selected._id != null && this.selected._id.$oid != null) {
        this.isLoading = true;
        amazon.evadi(this.selected._id.$oid, this.qtaOrd).then((ris) => {
          this.isLoading = false;
          if (ris.startsWith("ERR")) {
            this.msgerr = ris;
            this.msgboxerr = true;
          } else {
            this.userDeleted = true;
            this.qtaOrd=null;
            for (var j = 0; j < this.ordini.length; j++) {
              if (this.ordini[j]._id.$oid === this.selected._id.$oid) {
                this.ordini.splice(j, 1);
              }
            }
          }
        });
      }
    },
    spedisci(i) {
      this.selected = i;
      this.promptQtaSpedito = true;
    },
    spedisci2() {
      if (this.selected._id != null && this.selected._id.$oid != null) {
        this.isLoading = true;
        amazon.spedisci(this.selected._id.$oid, this.qtaSped).then((ris) => {
          this.isLoading = false;
          if (ris.startsWith("ERR")) {
            this.msgerr = ris;
            this.msgboxerr = true;
          } else {
            this.orderSent = true;
            this.qtaSped=null;
            for (var j = 0; j < this.ordini.length; j++) {
              if (this.ordini[j]._id.$oid === this.selected._id.$oid) {
                this.ordini.splice(j, 1);
              }
            }
          }
        });
      }
    },
    onCancel() {},
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.ve-table {
  &-actions {
    width: 104px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  &-btn {
    height: 24px;
    min-width: 32px;
    padding: 0 8px;
    text-align: center;
    border-radius: 4px;

    cursor: pointer;
    justify-content: center;
    outline: none;
    border: none;
    position: relative;
    white-space: nowrap;
    &-primary {
      background: #3844cc;
      color: white;
    }
    &-danger {
      background: #e24e40;
      color: white;
    }
  }
}
</style>
