<template>
  <div style="margin-top:20px;">
    <form novalidate class="md-layout" @submit.prevent="validateUser">
      <md-card class="md-layout-item md-size-50 md-small-size-100">
        <md-card-header>
          <div class="md-title">Aggiungi utente</div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('firstName')">
                <label for="first-name">Nome</label>
                <md-input
                  name="first-name"
                  id="first-name"
                  autocomplete="given-name"
                  v-model="form.firstName"
                  :disabled="isLoading"
                />
                <span class="md-error" v-if="!$v.form.firstName.required"
                  >Il nome è obbligatorio</span
                >
                <span class="md-error" v-else-if="!$v.form.firstName.minlength"
                  >Nome non valido</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('lastName')">
                <label for="last-name">Cognome</label>
                <md-input
                  name="last-name"
                  id="last-name"
                  autocomplete="family-name"
                  v-model="form.lastName"
                  :disabled="isLoading"
                />
                <span class="md-error" v-if="!$v.form.lastName.required"
                  >Il cognome è obbligatorio</span
                >
                <span class="md-error" v-else-if="!$v.form.lastName.minlength"
                  >Cognome non valido</span
                >
              </md-field>
            </div>
          </div>

          <md-field :class="getValidationClass('email')">
            <label for="email">Email</label>
            <md-input
              type="email"
              name="email"
              id="email"
              autocomplete="email"
              v-model="form.email"
              :disabled="isLoading"
            />
            <span class="md-error" v-if="!$v.form.email.required"
              >La email è obbligatoria</span
            >
            <span class="md-error" v-else-if="!$v.form.email.email"
              >Email non valida</span
            >
          </md-field>

          <div class="md-layout">
            <md-checkbox
              type="check"
              name="admin"
              id="admin"
              v-model="form.admin"
              :disabled="isLoading"
              >Admin</md-checkbox
            >
          </div>
        </md-card-content>

        <loading
          :active.sync="isLoading"
          :can-cancel="true"
          :on-cancel="onCancel"
          :is-full-page="true"
          color="#a20204"
        ></loading>

        <md-card-actions>
          <md-button type="submit" class="md-primary" :disabled="isLoading"
            >Aggiungi utente</md-button
          >
        </md-card-actions>
      </md-card>

      <md-snackbar :md-active.sync="userSaved"
        >Anagrafica salvata con successo</md-snackbar
      >
    </form>

    <VueyeTable
      :data="anagrafiche"
      :columns="columns"
      title="Utenti in archivio"
      style="margin-top:20px;"
    >
      <template v-slot:admin="{ item }">
        <div v-if="item.admin">
          <md-icon>verified</md-icon>
        </div>
      </template>
      <template v-slot:actions="{ item }">
        <div class="ve-table-actions">
          <button
            class="ve-table-btn ve-table-btn-danger"
            @click="deleteItem(item)"
          >
            Cancella
          </button>
        </div>
      </template>
    </VueyeTable>

    <md-dialog-alert
      :md-active.sync="msgboxerr"
      md-title="Avvenuto errore"
      :md-content="msgerr"
    />

    <md-dialog-confirm
      :md-active.sync="msgConferma"
      :md-title="msgConfermaTitle"
      :md-content="msgConfermaText"
      :md-confirm-text="msgConfermaYes"
      :md-cancel-text="msgConfermaNo"
      @md-cancel="onCancel"
      @md-confirm="onConfirm"
    />

    <md-snackbar :md-active.sync="userDeleted">
      Anagrafica cancellata con successo
    </md-snackbar>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email, minLength } from "vuelidate/lib/validators";

import amazon from "../js/amazon.js";

import VueyeTable from "vueye-table";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "FormValidation",
  mixins: [validationMixin],
  components: {
    VueyeTable,
    Loading
  },
  data: () => ({
    form: {
      firstName: null,
      lastName: null,
      admin: null,
      email: null,
    },
    userSaved: false,
    userDeleted: false,
    isLoading: false,
    msgboxerr: false,
    msgConferma: false,
    msgerr: "",
    msgConfermaTitle: "",
    msgConfermaText: "",
    msgConfermaYes: "",
    msgConfermaNo: "",
    anagrafiche: [],
    columns: [
      {
        key: "nome",
        label: "Nome",
        sortable: false,
        display: true,
      },
      {
        key: "cognome",
        label: "Cognome",
        sortable: true,
        display: true,
      },
      {
        key: "email",
        label: "E-Mail",
        display: true,
        sortable: true,
      },
      {
        key: "admin",
        label: "Admin",
        sortable: true,
        display: true,
      },
      {
        key: "actions",
        label: "Actions",
        sortable: false,
        display: true,
      },
    ],
    selectedEmail: "",
  }),
  validations: {
    form: {
      firstName: {
        required,
        minLength: minLength(3),
      },
      lastName: {
        required,
        minLength: minLength(3),
      },
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    init() {
      this.isLoading=true;
      amazon.getAnagrafiche(this.form).then((ris) => {
        this.isLoading=false;
        if (ris.startsWith("ERR")) {
          this.msgerr = ris;
          this.msgboxerr = true;
        } else {
          this.anagrafiche = JSON.parse(ris);
          //console.log(JSON.stringify(this.anagrafiche));
        }
      });
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.firstName = null;
      this.form.lastName = null;
      this.form.email = null;
    },
    saveUser() {
      this.isLoading = true;
      amazon.addAnagrafica(this.form).then((ris) => {
        if (ris.startsWith("ERR")) {
          this.msgerr = ris;
          this.msgboxerr = true;
        } else {
          this.userSaved = true;
          var an = {};
          an.nome = this.form.firstName;
          an.cognome = this.form.lastName;
          an.admin = this.form.admin;
          an.email = this.form.email;
          this.anagrafiche.push(an);
          this.clearForm();
        }
        this.isLoading = false;
      });
    },
    validateUser() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.saveUser();
      }
    },
    deleteItem(i) {            
      this.selectedEmail = i.email;
      this.msgConfermaTitle = "Conferma cancellazione";
      this.msgConfermaText =
        "Procedo con la cancellazione dell'utente selezionato ?";
      this.msgConfermaYes = "PROCEDI";
      this.msgConfermaNo = "ANNULLA";
      this.msgConferma = true;
    },
    onConfirm() {
      this.isLoading = true;
      amazon.deleteAnagrafica(this.selectedEmail).then((ris) => {
        this.isLoading = false;
        if (ris.startsWith("ERR")) {
          this.msgerr = ris;
          this.msgboxerr = true;
        } else {
          this.userDeleted = true;
          for (var i = 0; i < this.anagrafiche.length; i++) {
            if (this.anagrafiche[i].email === this.selectedEmail) {
              this.anagrafiche.splice(i, 1);
            }
          }
        }
      });
    },
    onCancel() {},
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}


.ve-table {
  &-actions {
    width: 104px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  &-btn {
    height: 24px;
    min-width: 32px;
    padding: 0 8px;
    text-align: center;
    border-radius: 4px;

    cursor: pointer;
    justify-content: center;
    outline: none;
    border: none;
    position: relative;
    white-space: nowrap;
    &-primary {
      background: #3844cc;
      color: white;
    }
    &-danger {
      background: #e24e40;
      color: white;
    }
  }
}
</style>
