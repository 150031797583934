
var AWS = require("aws-sdk")

AWS.config.region = 'eu-central-1'; // Regione
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: 'eu-central-1:d44e3f54-4987-4caa-bc35-533610c2d5c9',
});

var lambda = new AWS.Lambda({
  region: 'eu-central-1',
  apiVersion: apiVersion
});

var apiVersion = '2015-03-31';
var apiVersionS3 = '2006-03-01';

function pulisciDoppieVirgolette(risp) {
  var ret;
  if (risp.charAt(0) === '"' && risp.charAt(risp.length - 1) === '"') {
    ret = risp.substr(1,
      risp.length - 2);
  }

  return ret;
}

// Definisco la mia funzione ReplaceAll
function replaceAll(str, cerca, sostituisci) {
  if (typeof str === "undefined" || str == null) {
    return str;
  } else {
    return str.split(cerca).join(sostituisci);
  }
}

var amazon = {


  addAnagrafica(form) {  
    return new Promise((resolve, reject) => {
      var an = {};
      an.nome = form.firstName
      an.cognome = form.lastName
      an.admin = form.admin
      an.email = form.email

      var pullParams = {
        FunctionName: 'BaldoliniAddAnagrafica',
        InvocationType: 'RequestResponse',
        LogType: 'None',
        Payload: JSON.stringify(an)
      };

      //console.log(pullParams.Payload);

      lambda.invoke(pullParams, function (err, data) {
        if (err) {
          prompt(err);
        } else {
          if (data.Payload != 'null') {
            resolve(pulisciDoppieVirgolette(data.Payload));
          } else {
            resolve("ERR");
          }
        }
      });
    });
  },
  addOrdine(form,da) {

    return new Promise((resolve, reject) => {

      var ordine = {};
      ordine.codice = form.codice
      ordine.descrizione = form.descrizione
      ordine.qta = form.qta
      ordine.marca = form.marca
      ordine.urgenza = form.urgenza
      ordine.insda = da

      var pullParams = {
        FunctionName: 'BaldoliniAddOrdine',
        InvocationType: 'RequestResponse',
        LogType: 'None',
        Payload: JSON.stringify(ordine)
      };

      //console.log(pullParams);

      lambda.invoke(pullParams, function (err, data) {
        //console.log(data);
        if (err) {
          prompt(err);
        } else {
          if (data.Payload != 'null') {
            resolve(pulisciDoppieVirgolette(data.Payload));
          } else {
            resolve("ERR");
          }
        }
      });
    });


  },
  getAnagrafiche() {

    return new Promise((resolve, reject) => {

      var pullParams = {
        FunctionName: 'BaldoliniGetAnagrafiche',
        InvocationType: 'RequestResponse',
        LogType: 'None',
        Payload: '{}'
      };

      //console.log(pullParams.Payload);

      lambda.invoke(pullParams, function (err, data) {
        if (err) {
          prompt(err);
        } else {
          if (data.Payload != 'null') {
            var risp = pulisciDoppieVirgolette(data.Payload);
            risp = replaceAll(risp, '\\"', '"');
            risp = replaceAll(risp, '\\\\', '\\');
            resolve(risp);
          } else {
            resolve("ERR");
          }
        }
      });
    });


  },
  login(email) {

    return new Promise((resolve, reject) => {

      var pullParams = {
        FunctionName: 'BaldoliniIsEnabled',
        InvocationType: 'RequestResponse',
        LogType: 'None',
        Payload: '{"email" : "' + email + '"}'
      };

      //console.log(pullParams.Payload);

      lambda.invoke(pullParams, function (err, data) {
        //console.log(data);
        if (err) {
          prompt(err);
        } else {
          if (data.Payload != 'null') {
            var risp = pulisciDoppieVirgolette(data.Payload);
            risp = replaceAll(risp, '\\"', '"');
            risp = replaceAll(risp, '\\\\', '\\');
            resolve(risp);
          } else {
            resolve("ERR");
          }
        }
      });
    });


  },
  getOrdini(insid) {

    return new Promise((resolve, reject) => {

      var pullParams = {
        FunctionName: 'BaldoliniGetOrdini',
        InvocationType: 'RequestResponse',
        LogType: 'None',
        Payload: '{"insda":"' + insid + '"}'
      };

      //console.log(pullParams.Payload);

      lambda.invoke(pullParams, function (err, data) {
        if (err) {
          prompt(err);
        } else {
          if (data.Payload != 'null') {
            var risp = pulisciDoppieVirgolette(data.Payload);
            risp = replaceAll(risp, '\\"', '"');
            risp = replaceAll(risp, '\\\\', '\\');
            resolve(risp);
          } else {
            resolve("ERR");
          }
        }
      });
    });
  },
  getStorico(form, moment) {

    return new Promise((resolve, reject) => {

      var pl={}
      if (form.dal !=null){
        pl.dal = moment(form.dal).format("X");
      }

      if (form.al !=null){
        pl.al = moment(form.al).format("X");
      }

      pl.evaso =form.evaso
      pl.spedito =form.spedito

      var pullParams = {
        FunctionName: 'BaldoliniStoricoOrdini',
        InvocationType: 'RequestResponse',
        LogType: 'None',
        Payload: JSON.stringify(pl)
      };

      //console.log(pullParams.Payload);

      lambda.invoke(pullParams, function (err, data) {
        if (err) {
          prompt(err);
        } else {
          if (data.Payload != 'null') {
            var risp = pulisciDoppieVirgolette(data.Payload);
            risp = replaceAll(risp, '\\"', '"');
            risp = replaceAll(risp, '\\\\', '\\');
            //console.log(risp);           
            resolve(risp);
          } else {
            resolve("ERR");
          }
        }
      });
    });
  },
  cercaDescrizione(desc) {

    return new Promise((resolve, reject) => {

      var pullParams = {
        FunctionName: 'BaldoliniSearch',
        InvocationType: 'RequestResponse',
        LogType: 'None',
        Payload: '{"descrizione" : "' + desc + '"}'
      };

      //console.log(pullParams.Payload);

      lambda.invoke(pullParams, function (err, data) {
        if (err) {
          prompt(err);
        } else {
          if (data.Payload != 'null') {
            //console.log(data.Payload);
            var risp = pulisciDoppieVirgolette(data.Payload);
            risp = replaceAll(risp, '\\"', '"');
            risp = replaceAll(risp, '\\\\', '\\');
            resolve(risp);
          } else {
            resolve("ERR");
          }
        }
      });
    });


  },
  cercaCodice(desc) {

    return new Promise((resolve, reject) => {

      var pullParams = {
        FunctionName: 'BaldoliniSearch',
        InvocationType: 'RequestResponse',
        LogType: 'None',
        Payload: '{"codice" : "' + desc + '"}'
      };

      //console.log(pullParams.Payload);

      lambda.invoke(pullParams, function (err, data) {
        if (err) {
          prompt(err);
        } else {
          if (data.Payload != 'null') {            
            //console.log(data.Payload);
            var risp = pulisciDoppieVirgolette(data.Payload);            
            risp = replaceAll(risp, '\\"', '"');
            risp = replaceAll(risp, '\\\\', '\\');
            resolve(risp);
          } else {
            resolve("ERR");
          }
        }
      });
    });


  },
  deleteAnagrafica(mail) {

    return new Promise((resolve, reject) => {
      var a = {};
      a.email = mail;

      var pullParams = {
        FunctionName: 'BaldoliniDeleteAnagrafica',
        InvocationType: 'RequestResponse',
        LogType: 'None',
        Payload: JSON.stringify(a)
      };

      //console.log(pullParams.Payload);

      lambda.invoke(pullParams, function (err, data) {
        if (err) {
          prompt(err);
        } else {
          if (data.Payload != 'null') {
            var risp = pulisciDoppieVirgolette(data.Payload);
            risp = replaceAll(risp, '\\"', '"');
            risp = replaceAll(risp, '\\\\', '\\');
            resolve(risp);
          } else {
            resolve("ERR");
          }
        }
      });
    });


  },
  deleteOrdine(id) {

    return new Promise((resolve, reject) => {
      var a = {};
      a.id = id;

      var pullParams = {
        FunctionName: 'BaldoliniDeleteOrdine',
        InvocationType: 'RequestResponse',
        LogType: 'None',
        Payload: JSON.stringify(a)
      };

      //console.log(pullParams.Payload);

      lambda.invoke(pullParams, function (err, data) {
        if (err) {
          prompt(err);
        } else {
          if (data.Payload != 'null') {
            var risp = pulisciDoppieVirgolette(data.Payload);
            risp = replaceAll(risp, '\\"', '"');
            risp = replaceAll(risp, '\\\\', '\\');
            resolve(risp);
          } else {
            resolve("ERR");
          }
        }
      });
    });


  },
  evadi(id,qta) {
    return new Promise((resolve, reject) => {
      var a = {};
      a.id = id;
      a.qtaEvaso=qta;

      var pullParams = {
        FunctionName: 'BaldoliniSetEvaso',
        InvocationType: 'RequestResponse',
        LogType: 'None',
        Payload: JSON.stringify(a)
      };

      console.log(pullParams.Payload);

      lambda.invoke(pullParams, function (err, data) {
        if (err) {
          prompt(err);
        } else {
          if (data.Payload != 'null') {
            var risp = pulisciDoppieVirgolette(data.Payload);
            risp = replaceAll(risp, '\\"', '"');
            risp = replaceAll(risp, '\\\\', '\\');
            resolve(risp);
          } else {
            resolve("ERR");
          }
        }
      });
    });
  },
  spedisci(id,qta) {
    return new Promise((resolve, reject) => {
      var a = {};
      a.id = id;
      a.qtaSpedito=qta;

      var pullParams = {
        FunctionName: 'BaldoliniSetSpedito',
        InvocationType: 'RequestResponse',
        LogType: 'None',
        Payload: JSON.stringify(a)
      };

      console.log(pullParams.Payload);

      lambda.invoke(pullParams, function (err, data) {
        if (err) {
          prompt(err);
        } else {
          if (data.Payload != 'null') {
            var risp = pulisciDoppieVirgolette(data.Payload);
            risp = replaceAll(risp, '\\"', '"');
            risp = replaceAll(risp, '\\\\', '\\');
            resolve(risp);
          } else {
            resolve("ERR");
          }
        }
      });
    });
  },
}

export default amazon