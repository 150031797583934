<template>
  <div class="home" style="margin-top:50px;">    
    <h2>
      Benvenuto nel gestionale ordini della BST s.r.l.
    </h2>
  </div>
</template>

<script>


export default {
  name: 'Home',
  components: {

  }
}
</script>
