import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import anagrafica from '../views/anagrafica.vue'
import ordine from '../views/ordine.vue'
import storico from '../views/storico.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/anagrafica',
    name: 'anagrafica',
    component: anagrafica
  },
  {
    path: '/ordine',
    name: 'ordine',
    component: ordine
  },
  {
    path: '/storico',
    name: 'storico',
    component: storico
  },  
]

const router = new VueRouter({
  routes
})


export default router
