import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import 'aws-sdk'
import VueComp from '@vue/composition-api';
import VueCookies from 'vue-cookies'
import moment from 'vue-moment'
const HelloJs = require('hellojs/dist/hello.all.min.js');
const VueHello = require('vue-hellojs');

import DisableAutocomplete from 'vue-disable-autocomplete';

Vue.use(DisableAutocomplete);

HelloJs.init({
  google: '597938761321-gi9ruie024marb36gp6j5to3am9jab0i.apps.googleusercontent.com',
}, {
  redirect_uri: 'https://www.baldolini.org/home'
});

Vue.use(VueHello, HelloJs);
Vue.use(VueCookies)
Vue.use(VueComp);
Vue.use(moment);
Vue.config.productionTip = false
Vue.use(VueMaterial)

Vue.material.locale.dateFormat = 'dd/MM/yyyy'

new Vue({
  router,  
  render: h => h(App)
}).$mount('#app')
