<template>
  <div id="app">
    <md-toolbar>
      <img src="./assets/logo.svg" style="max-width: 50px;" />
      <router-link to="/anagrafica" v-if="isLogged && isAdmin">
        <md-button class="md-icon-button">
          <md-icon>person_add</md-icon>
          <md-tooltip md-direction="bottom">Anagrafica</md-tooltip>
        </md-button>
      </router-link>
      <router-link to="/ordine" v-if="isLogged">
        <md-button class="md-icon-button">
          <md-icon>playlist_add</md-icon>
          <md-tooltip md-direction="bottom">Nuovo ordine</md-tooltip>
        </md-button>
      </router-link>  
      <router-link to="/storico">
        <md-button class="md-icon-button" v-if="isLogged && isAdmin">
          <md-icon>table_view</md-icon>
          <md-tooltip md-direction="bottom">Storico</md-tooltip>
        </md-button>
      </router-link>
      <md-button v-if="!isLogged" @click="auth('google')">
        Accedi con Google
      </md-button>
      <md-button
        class="md-icon-button"
        v-if="isLogged"
        @click="logout('google')"
      >
        <md-icon style="color:red">logout</md-icon>
        <md-tooltip md-direction="bottom">Esci </md-tooltip>
      </md-button>
    </md-toolbar>
    <router-view />

    <md-dialog-alert
      :md-active.sync="msgboxerr"
      md-title="Avvenuto errore"
      :md-content="msgerr"
    />

    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#a20204"
    ></loading>
  </div>
</template>

<script>
import amazon from "./js/amazon.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  mounted() {
    if (
      typeof this.$cookies.get("baldoliniid") !== "undefined" &&
      this.$cookies.get("baldoliniid") != null
    ) {
      this.isLogged = true;
    }
    if (
      typeof this.$cookies.get("baldolinia") !== "undefined" &&
      this.$cookies.get("baldolinia") != null
    ) {
      this.isAdmin = true;
    }
  },
  beforeCreate() {
    if (
      typeof this.$cookies.get("baldoliniid") === "undefined" ||
      this.$cookies.get("baldoliniid") == null
    ) {
      if (this.$router.currentRoute.fullPath != "/") {
        this.$router.push("/");
      }
    }
  },
  components: {
    Loading,
  },
  data: () => ({
    isLogged: false,
    isLoading: false,
    isAdmin: false,
    msgboxerr: false,
    msgerr: "",
  }),
  methods: {
    auth(network) {
      const hello = this.hello;

      hello(network)
        .login(network, { scope: "email" })
        .then(() => {
          const authRes = hello(network).getAuthResponse();
          /*
          performs operations using the token from authRes
        */
          hello(network)
            .api("me")
            .then((json) => {
              const profile = json;
              if (typeof profile.email !== "undefined") {
                this.isLoading = true;
                amazon.login(profile.email).then((ris) => {
                  //console.log(ris)
                  if (ris.startsWith("ERR")) {
                    this.msgerr = ris;
                    this.msgboxerr = true;
                  } else {
                    var p = JSON.parse(ris);
                    this.isLogged = true;
                    this.isAdmin = p.admin;
                    this.$cookies.set("baldoliniid", p.id, 60 * 60 * 12);
                    this.$cookies.set("baldolinia", p.admin, 60 * 60 * 12);
                  }
                  this.isLoading = false;
                });
              }
            });
        });
    },
    logout(network) {
      const hello = this.hello;
      hello(network).logout((e) => {
        this.isLogged = false;
        this.$cookies.remove("baldoliniid");
        this.$router.push("/");
      });
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,400italic|Material+Icons");

#app {
  font-family: Roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
