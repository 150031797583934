<template>
  <div style="margin-top:20px;">
    <form novalidate class="md-layout" @submit.prevent="validateUser">
      <md-card class="md-layout-item md-size-50 md-small-size-100">
        <md-card-header>
          <div class="md-title">Nuovo ordine</div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-autocomplete
                id="codice"
                v-model="form.codice"
                :md-options="catalogo"
                @md-changed="getCatalogoByCod"
                @md-selected="selectedDesc"
                :disabled="isLoading"
              >
                <label>Codice</label>

                <template slot="md-autocomplete-item" slot-scope="{ item }">
                  {{ item.c }}-{{ item.d }}
                </template>
              </md-autocomplete>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field>
                <label for="marca">Marca</label>
                <md-input
                  name="marca"
                  id="marca"
                  v-model="form.marca"
                  :disabled="isLoading"
                />
              </md-field>
            </div>
          </div>

          <md-autocomplete
            id="txtDescrizione"
            v-model="form.descrizione"
            :md-options="catalogo"
            @md-changed="getCatalogo"
            @md-selected="selectedDesc"
            :disabled="isLoading"
            :class="getValidationClass('descrizione')"
          >
            <label>Descrizione</label>

            <template slot="md-autocomplete-item" slot-scope="{ item }">{{
              item.d
            }}</template>

            <span class="md-error" v-if="!$v.form.descrizione.required"
              >La descrizione è obbligatoria</span
            >
          </md-autocomplete>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('qta')">
                <label for="qta">Quantità</label>
                <md-input
                  name="qta"
                  id="qta"
                  v-model="form.qta"
                  :disabled="isLoading"
                />
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-radio v-model="form.urgenza" value="no">Non urgente</md-radio>
              <md-radio v-model="form.urgenza" value="urgente"
                >Urgente</md-radio
              >
              <md-radio v-model="form.urgenza" value="urgentissimo"
                >Urgentissimo</md-radio
              >
            </div>
          </div>
        </md-card-content>

        <loading
          :active.sync="isLoading"
          :can-cancel="true"
          :on-cancel="onCancel"
          :is-full-page="true"
          color="#a20204"
        ></loading>

        <md-card-actions>
          <md-button type="submit" class="md-primary" :disabled="isLoading"
            >Ordina prodotto</md-button
          >
        </md-card-actions>
      </md-card>

      <md-snackbar :md-active.sync="userSaved"
        >Ordine memorizzato con successo</md-snackbar
      >
    </form>

    <VueyeTable
      :data="ordini"
      :columns="columns"
      title="I tuoi ultimi ordini"
      style="margin-top:20px;"
      :config="config"
    >
      <template v-slot:admin="{ item }">
        <div v-if="item.admin">
          <md-icon>verified</md-icon>
        </div>
      </template>
      <template v-slot:ordinato="{ item }">
        {{ item.ord }}&nbsp;({{ item.qte }})
      </template>
      <template v-slot:spedito="{ item }">
        {{ item.sped }}&nbsp;({{ item.qts }})
      </template>
      <template v-slot:actions="{ item }">
        <div class="ve-table-actions">
          <button
            v-if="item.ord == null"
            class="ve-table-btn ve-table-btn-danger"
            @click="deleteItem(item)"
          >
            Cancella
          </button>
        </div>
      </template>
    </VueyeTable>

    <md-dialog-alert
      :md-active.sync="msgboxerr"
      md-title="Avvenuto errore"
      :md-content="msgerr"
    />

    <md-dialog-confirm
      :md-active.sync="msgConferma"
      :md-title="msgConfermaTitle"
      :md-content="msgConfermaText"
      :md-confirm-text="msgConfermaYes"
      :md-cancel-text="msgConfermaNo"
      @md-cancel="onCancel"
      @md-confirm="onConfirm"
    />

    <md-snackbar :md-active.sync="userDeleted">
      Ordine cancellato con successo
    </md-snackbar>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email, minLength } from "vuelidate/lib/validators";

import amazon from "../js/amazon.js";

import VueyeTable from "vueye-table";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "FormValidation",
  mixins: [validationMixin],
  components: {
    VueyeTable,
    Loading,
  },
  data: () => ({
    catalogo: [{ c: "", d: "" }],
    searchTerm: "",
    form: {
      codice: null,
      descrizione: null,
      marca: null,
      qta: null,
      urgenza: "no",
    },
    userSaved: false,
    userDeleted: false,
    isLoading: false,
    msgboxerr: false,
    msgConferma: false,
    noSearch: false,
    msgerr: "",
    msgConfermaTitle: "",
    msgConfermaText: "",
    msgConfermaYes: "",
    msgConfermaNo: "",
    ordini: [],
    config: {
      filterBy: "Cerca per",
      search: "Cerca",
      nbRowsPerPage: "Numero di righe per pagina",
      of: "di",
    },
    columns: [
      {
        key: "ins",
        label: "Inserito",
        sortable: true,
        display: true,
      },
      {
        key: "codice",
        label: "Codice",
        sortable: false,
        display: true,
      },
      {
        key: "descrizione",
        label: "Descrizione",
        sortable: false,
        display: true,
      },
      {
        key: "marca",
        label: "Marca",
        display: true,
        sortable: true,
      },
      {
        key: "qta",
        label: "Qtà",
        sortable: false,
        display: true,
      },
      {
        key: "urgenza",
        label: "Urgenza",
        sortable: true,
        display: true,
      },
      {
        key: "ord",
        display: false,
      },
      {
        key: "sped",
        display: false,
      },
      {
        key: "ordinato",
        label: "Ordinato",
        sortable: true,
        display: true,
      },
      {
        key: "spedito",
        label: "Spedito",
        sortable: true,
        display: true,
      },
      {
        key: "actions",
        label: "Azioni",
        sortable: false,
        display: true,
      },
      {
        key: "_id",
        display: false,
      },
      {
        key: "id",
        display: false,
      },
      {
        key: "qte",
        display: false,
      },
      {
        key: "qts",
        display: false,
      },
    ],
    selectedEmail: "",
  }),
  validations: {
    form: {
      descrizione: {
        required,
      },
      qta: {
        required,
      },
    },
  },
  methods: {
    init() {
      this.isLoading = true;
      amazon.getOrdini(this.$cookies.get("baldoliniid")).then((ris) => {
        this.isLoading = false;
        if (ris.startsWith("ERR")) {
          this.msgerr = ris;
          this.msgboxerr = true;
        } else {
          this.ordini = JSON.parse(ris);
          //console.log(JSON.stringify(this.ordini));
        }
      });
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.codice = null;
      this.form.descrizione = "";
      this.form.qta = null;
      this.form.marca = null;
      this.form.urgenza = "no";
      this.selectedEmail = null;
    },
    saveOrdine() {
      this.isLoading = true;
      amazon
        .addOrdine(this.form, this.$cookies.get("baldoliniid"))
        .then((ris) => {
          //console.log(ris);
          if (ris.startsWith("ERR")) {
            this.msgerr = ris;
            this.msgboxerr = true;
          } else {
            this.userSaved = true;
            var ordine = {};
            ordine.codice = this.form.codice;
            ordine.descrizione = this.form.descrizione;
            ordine.qta = this.form.qta;
            ordine.marca = this.form.marca;
            ordine.urgenza = this.form.urgenza;
            ordine.ins = this.$moment().format("DD/MM/YYYY");
            ordine.id = ris.substring(3);
            this.ordini.push(ordine);
            this.clearForm();
            //console.log(ordine);
          }
          this.isLoading = false;
        });
    },
    validateUser() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.saveOrdine();
      }
    },
    deleteItem(i) {
      if (typeof i._id !== "undefined" && i._id != null) {
        this.selectedEmail = i._id.$oid;
      } else if (i.id != null) {
        this.selectedEmail = i.id;
      }
      //console.log(this.selectedEmail)
      this.msgConfermaTitle = "Conferma cancellazione";
      this.msgConfermaText =
        "Procedo con la cancellazione dell'ordine selezionato ?";
      this.msgConfermaYes = "PROCEDI";
      this.msgConfermaNo = "ANNULLA";
      this.msgConferma = true;
    },
    onConfirm() {
      if (this.selectedEmail != null) {
        this.isLoading = true;
        amazon.deleteOrdine(this.selectedEmail).then((ris) => {
          this.isLoading = false;
          if (ris.startsWith("ERR")) {
            this.msgerr = ris;
            this.msgboxerr = true;
          } else {
            this.userDeleted = true;
            for (var i = 0; i < this.ordini.length; i++) {
              if (
                (this.ordini[i]._id != null &&
                  this.ordini[i]._id.$oid === this.selectedEmail) ||
                (this.ordini[i].id != null &&
                  this.ordini[i].id === this.selectedEmail)
              ) {
                this.ordini.splice(i, 1);
              }
            }
          }
        });
      }
    },
    onCancel() {},
    getCatalogo() {
      if (this.noSearch == true) {
        return;
      }
      if (this.form.descrizione != null && this.form.descrizione.length > 3) {
        amazon.cercaDescrizione(this.form.descrizione).then((ris) => {
          this.isLoading = false;
          if (ris.startsWith("ERR")) {
            this.msgerr = ris;
            this.msgboxerr = true;
          } else {
            this.catalogo = JSON.parse(ris);
            this.catalogo.forEach((element) => {
              element.toLowerCase = () => element.d.toLowerCase();
              element.toString = () => element.d;
            });
          }
        });
      }
    },
    getCatalogoByCod() {
      if (this.noSearch == true) {
        return;
      }
      if (this.form.codice != null && this.form.codice.length > 3) {
        amazon.cercaCodice(this.form.codice).then((ris) => {
          this.isLoading = false;
          if (ris.startsWith("ERR")) {
            this.msgerr = ris;
            this.msgboxerr = true;
          } else {
            this.catalogo = JSON.parse(ris);
            this.catalogo.forEach((element) => {
              element.toLowerCase = () => element.d.toLowerCase();
              element.toString = () => element.d;
            });
          }
        });
      }
    },
    selectedDesc(val) {
      this.noSearch = true;
      this.form.codice = val.c;
      this.form.descrizione = val.d;
      setTimeout(() => {
        this.noSearch = false;
      }, 2000);
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.ve-table {
  &-actions {
    width: 104px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  &-btn {
    height: 24px;
    min-width: 32px;
    padding: 0 8px;
    text-align: center;
    border-radius: 4px;

    cursor: pointer;
    justify-content: center;
    outline: none;
    border: none;
    position: relative;
    white-space: nowrap;
    &-primary {
      background: #3844cc;
      color: white;
    }
    &-danger {
      background: #e24e40;
      color: white;
    }
  }
}
</style>
